// ============================================================================
// DocumentsManagerApi
// -------------------
// Document manager api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments, NotImplementedYet } from '@/helpers/methods'

const folderUpdate = function (vendorId, parentFolderId, folderId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
	RequiredArguments({ vendorId: vendorId, parentFolderId: parentFolderId, folderId: folderId })
	return Backend.PATCH(
		URLS.api.vendors.folders.nodes.uri,
		[vendorId, parentFolderId, folderId],
		params,
		data,
		doCancelPreviousRequest,
		doCancelAllOtherRequests
	)
}

// -------
// Exports
// -------
export default {
	vendor: {
		document: {
			create: NotImplementedYet,
			read: function (vendorId, documentId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId: vendorId, documentId: documentId })
				return Backend.GET(URLS.api.vendors.documents.uri, [vendorId, documentId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
			update: function (vendorId, documentId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId: vendorId, documentId: documentId })
				return Backend.PATCH(URLS.api.vendors.documents.uri, [vendorId, documentId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
			delete: function (vendorId, documentId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId: vendorId, documentId: documentId })
				return Backend.DELETE(URLS.api.vendors.documents.uri, [vendorId, documentId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
			author: {
				create: NotImplementedYet,
				read: function (vendorId, documentId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, documentId: documentId })
					return Backend.GET(URLS.api.vendors.documents.author.uri, [vendorId, documentId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			},
			versions: {
				create: NotImplementedYet,
				read: function (vendorId, documentId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, documentId: documentId })
					return Backend.GET(URLS.api.vendors.documents.versions.uri, [vendorId, documentId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			}
		},
		folders: {
			create: NotImplementedYet,
			read: function (vendorId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId: vendorId })
				return Backend.GET(URLS.api.vendors.folders.uri, [vendorId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
			update: NotImplementedYet,
			delete: function (vendorId, folderIdentifier, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId: vendorId, folderIdentifier: folderIdentifier })
				return Backend.DELETE(URLS.api.vendors.folders.uri, [vendorId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			}
		},
		folder: {
			create: function (vendorId, parentId, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId: vendorId, parentId: parentId })
				return Backend.POST(URLS.api.vendors.folders.folder.uri, [vendorId, parentId], null, data, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
			read: function (vendorId, folderIdentifier, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId: vendorId, folderIdentifier: folderIdentifier })
				return Backend.GET(URLS.api.vendors.folders.uri, [vendorId, folderIdentifier], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
			update: folderUpdate,
			delete: function (vendorId, folderIdentifier, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId: vendorId, folderIdentifier: folderIdentifier })
				return Backend.DELETE(URLS.api.vendors.folders.uri, [vendorId, folderIdentifier], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
			nodes: {
				create: NotImplementedYet,
				read: function (vendorId, parentFolderId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, parentFolderId: parentFolderId })
					return Backend.GET(URLS.api.vendors.folders.nodes.uri, [vendorId, parentFolderId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			},
			document: {
				create: function (vendorId, parentFolderId, file, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, parentFolderId: parentFolderId, file: file })
					return Backend.CHUNKUPLOAD(
						URLS.api.vendors.folders.document.uri,
						[vendorId, parentFolderId],
						params,
						file,
						doCancelPreviousRequest,
						doCancelAllOtherRequests
					)
				},
				read: function (vendorId, parentFolderId, documentId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, parentFolderId: parentFolderId, documentId: documentId })
					return Backend.GET(
						URLS.api.vendors.folders.nodes.uri,
						[vendorId, parentFolderId, documentId],
						params,
						doCancelPreviousRequest,
						doCancelAllOtherRequests
					)
				},
				update: function (vendorId, parentFolderId, documentId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, parentFolderId: parentFolderId, documentId: documentId })
					return Backend.PATCH(
						URLS.api.vendors.folders.nodes.uri,
						[vendorId, parentFolderId, documentId],
						params,
						data,
						doCancelPreviousRequest,
						doCancelAllOtherRequests
					)
				},
				delete: NotImplementedYet
			},
			folder: {
				create: NotImplementedYet,
				read: NotImplementedYet,
				update: folderUpdate,
				delete: NotImplementedYet
			},
			folders: {
				create: NotImplementedYet,
				read: NotImplementedYet,
				update: NotImplementedYet,
				delete: NotImplementedYet
			},
			documents: {
				create: NotImplementedYet,
				read: NotImplementedYet,
				update: NotImplementedYet,
				delete: NotImplementedYet
			},
			catalogFolders: {
				create: function (vendorId, parentId, catalogFolderId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, parentId: parentId, catalogFolderId: catalogFolderId })
					return Backend.POST(
						URLS.api.vendors.folders.catalogFolders.uri,
						[vendorId, parentId, catalogFolderId],
						params,
						doCancelPreviousRequest,
						doCancelAllOtherRequests
					)
				},
				read: function (vendorId, folderId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, folderId: folderId })
					return Backend.GET(URLS.api.vendors.folders.catalogFolders.uri, [vendorId, folderId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			}
		}
	}
}
